import './mixPanelInit';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { globalScopeReducer } from '@app/state/reducers/global-scope.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ABTestingService } from '@providers/ab-testing/ab-testing.service';
import { boostrapProviders } from '@providers/bootstrap';
import { remoteConfigFactory, RemoteConfigService } from '@providers/config/remote-config.service';
import { CountryInitializer } from '@providers/country/country.initializer';
import { DeviceDetectorService } from '@providers/device-ks/device-detector.service';
import { DevicePlatformInitializer } from '@providers/device-ks/device-platform.initializer';
import { FriendsService } from '@providers/friends/friends';
import { GlobalErrorHandler } from '@providers/global/global-error-handler';
import { GtvZone } from '@providers/gtv/gtv';
import { TranslateInitializer } from '@providers/i18n/translate.initializer';
import { LocalStorageModule } from '@providers/local-storage/local-storage.module';
import { ModalService } from '@providers/modal/modal';
import { RegisterWebService } from '@providers/registerWeb/registerWeb';
import { MovistarHomeModule } from 'src/app/pages/movistar-home/movistar-home.module';
import { TokenInterceptor } from 'src/providers/api/token.interceptor';
import { KSFirebase } from 'src/providers/firebase/ksfirebase';
import { AuthTokenService } from 'src/providers/user/auth-token.service';
import { CountryResolver } from 'src/resolvers/country.resolver';
import { TrackJS } from 'trackjs';

import { SmartRouterAnimationWrapModule } from '../components/smart-router-animation-wrap/smart-router-animation-wrap.module';
// environments vars
import { environment } from '../environments/environment';
import { ErrorInterceptor } from '../providers/api/error-interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { SharedModule } from './shared/shared.module';

TrackJS.install({
  token: 'a693ea8020e64a579b2ae76104c6c406',
  application: 'kanto',
  version: environment.version,
  // for more configuration options, see https://docs.trackjs.com
});

export const otherServices = [GtvZone, ModalService, RegisterWebService, FriendsService];

export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);

const initAppFactory =
  (
    devicePlatformService: DevicePlatformInitializer,
    countryService: CountryInitializer,
    translateService: TranslateInitializer,
    abTestingService: ABTestingService,
    authTokenService: AuthTokenService
  ) =>
  async () => {
    await devicePlatformService.load();
    await countryService.load();
    await Promise.all([translateService.load(), abTestingService.load(), authTokenService.load()]);
  };

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'karaokeApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    SharedModule,
    SmartRouterAnimationWrapModule, // ==========> is this required here?
    MovistarHomeModule, // ==========> is this required here?
    LocalStorageModule.detect(),
    StoreModule.forRoot({
      globalScope: globalScopeReducer,
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({}),
  ],
  providers: [
    CountryInitializer,
    CountryResolver,
    TranslateInitializer,
    ABTestingService,
    RemoteConfigService,
    KSFirebase,
    DeviceDetectorService,
    DevicePlatformInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory,
      deps: [DevicePlatformInitializer, CountryInitializer, TranslateInitializer, ABTestingService, AuthTokenService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: remoteConfigFactory,
      deps: [RemoteConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    boostrapProviders,
    otherServices,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
