import { Inject, Injectable } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { DeviceKS } from '@providers/device-ks/device-ks';
import { AllowedDevicePlatformsType } from '@providers/device-ks/device-platform.interface';
import { LocationService } from '@providers/helpers/location';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { Platform } from '@providers/platform/platform';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import { CountryService } from './country';

@Injectable()
export class CountryInitializer {
  constructor(
    private country: CountryService,
    private location: LocationService,
    private platform: Platform,
    private localStorage: LocalStorage,
    private deviceKS: DeviceKS,
    @Inject(REQUEST) private request: any
  ) {}

  load(): Promise<any> {
    return new Promise(async resolve => {
      const countryUrl = this.location.getUrlParameter('country');
      if (this.deviceKS.devicePlatform?.type === AllowedDevicePlatformsType.vizio) {
        this.country.setCountry('US');
      } else if (this.deviceKS.devicePlatform.type === AllowedDevicePlatformsType.movistar) {
        this.country.setCountry('ES');
      } else if (countryUrl) {
        this.country.setCountry(countryUrl);
      } else {
        await this.localStorage.$isReady.toPromise().then(() => {});
        const localStorageCountry: string = await this.localStorage.getItem('country');
        if (localStorageCountry) {
          this.country.setCountry(localStorageCountry);
        }
      }

      if (!this.country.isValidCountry(this.country.currentCountry)) {
        let ip;
        if (this.platform.isServer()) {
          ip = this.request.headers['x-forwarded-for']
            ? this.request.headers['x-forwarded-for'].split(',')[0]
            : undefined || this.request.connection.remoteAddress;
          if (ip === '::1') {
            ip = undefined;
          }
        }
        if (Array.isArray(ip)) {
          ip = ip[0];
        }
        console.log('ip detectada es', ip);
        this.country
          .callCountryApi(ip)
          .pipe(
            catchError(e => {
              if (this.deviceKS.devicePlatform?.type === 'vizio') {
                this.country.setCountry('US');
                return of('US');
              } else {
                this.country.setCountry('NN');
                return of('NN');
              }
            })
          )
          .subscribe(() => {
            if (this.platform.isBrowser()) {
              this.country.getClientCountry();
            }
            this.country.registerCountryDataOnMetrics();
            TrackJS.addMetadata('country', this.country.currentCountry);
            resolve(this.country.currentCountry);
          });
      } else {
        if (this.platform.isBrowser()) {
          this.country.getClientCountry();
        }
        this.country.country.next(this.country.currentCountry);
        this.country.registerCountryDataOnMetrics();
        TrackJS.addMetadata('country', this.country.currentCountry);
        resolve(this.country.currentCountry);
      }
    });
  }
}
